const { default: Resume } = require("./components/Resume");

function App() {
  return (
    <div>
      <Resume/>
    </div>
  );
}

export default App;
